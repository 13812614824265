import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    dragonGraph: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const { dragonGraph } = props.data;

  return (
    <Layout title="Why Is the World So Chaotic?">
      <Heading />
      <Core.ContentWrapper>
        <Core.ContentSection>
          <Core.LargeText>
            In this “post-post-Cold War era,” there’s been a gradual but
            unmistakable erosion of U.S. and Western primacy. The result, says
            SAIS’ Hal Brands: a return of great power competition, a revival of
            global ideological struggle, and the empowerment of “the agents of
            international strife and disorder.”
          </Core.LargeText>
          <Core.LargeText>
            If you think the world seems like a particularly chaotic place, you
            are far from alone. International security expert{' '}
            <Core.BoldText>Hal Brands</Core.BoldText> believes that perception
            is an understandable outcome of the sorting out process following
            decades of the Cold War.
          </Core.LargeText>
          <p>
            <Core.LeadIn color="red">
              &ldquo;The United States and its allies
            </Core.LeadIn>{' '}
            were so dominant in the years after the Cold War that this was an
            era almost without precedent in the history of international
            relations,” says Brands, the Henry A. Kissinger Distinguished
            Professor of Global Affairs at SAIS’ Henry A. Kissinger Center for
            Global Affairs.
          </p>
          <p>
            But many came to see those years as the new normal: The huge threat
            of totalitarian communism had disappeared, and liberal democracy had
            prevailed. Maybe all wasn’t right with the world, but so many wrongs
            were gone that it seemed like it had become a much safer place ...
            forever.
          </p>
          <p>
            Why does it seem so dangerous now? Because, not surprisingly, that
            dominance did not last.
          </p>
          <p>
            “During the 1990s, the primary pre-occupation of U.S. policymakers
            was humanitarian crises and nuclear proliferation,” Brands says. “In
            the 2000s, it was those two plus terrorism. Since 2008, it’s been
            all of those plus a return to great power competition. The result is
            we’re getting all types of instability and rivalry that are leading
            to a number of different conflicts all at once in all major
            geopolitical arenas.”
          </p>
          <p>
            Brands points out that the United States emerged from the Cold War
            with such world economic dominance that it accounted for nearly 25
            percent of global GDP in 1994 and an even larger share of global
            military power.
          </p>
          <p>
            And he wants you to know that the U.S. is still the world’s dominant
            power—by far. But there is no doubt that in what he terms the
            post-post-Cold War era, there has been a gradual but unmistakable
            erosion of U.S. and Western primacy. That’s led to a return of great
            power competition, a revival of global ideological struggle, and the
            empowerment of what he calls “the agents of international strife and
            disorder.”
          </p>
          <p>
            The rise of China, not just economically but also militarily, is
            evident. That nation is asserting itself in the South China Sea in
            ways that would have been unthinkable 25 years ago. Authoritarian
            regimes, of the type that were supposed to disappear along with the
            Cold War, have returned with a literal vengeance. Putin’s Russia, in
            particular, has shown its willingness to project its power, both
            with its neighbors (Georgia and Ukraine) and allies further afield:
            Assad’s Syria.
          </p>
          <p>
            One of the reasons for the rise of authoritarians is that they
            promise stability in a time of chaos. “The world has gone through a
            period of extremely disorienting change, in part due to
            globalization,” Brands says. “People do look for the sense of
            cohesion that an authoritarian regime can provide.
          </p>
          <p>
            “But the other thing that’s happened is that authoritarian regimes
            got smarter,” Brands says. “They took note of the rise of liberal
            democracy and refined their own tools of popular control and
            oppression. And they went on the offensive, promoting themselves
            both at home and abroad. You can see that with the Russian
            interference in elections in Europe and in our own.”
          </p>
          <Core.FullWidthImage
            image={dragonGraph}
            alt="graph with dragon head illustration"
          />
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Blockquote>
              As messy as the world is, it’s going to be a heck of a lot messier
              if the U.S. is not involved. One of the reasons Europe has not
              relapsed into feverish instability and violent conflict is that
              the U.S. has been there providing security. The same is true in
              Asia.
            </Core.Blockquote>
          }
        >
          <p>
            All of this apparent chaos does not make Brands nostalgic for the
            Cold War. “I don’t think the Cold War is something to look back on
            with fondness,” he says. “The dangers were severe.”
          </p>
          <p>
            Brands says that the current situation is not the result of blunders
            by U.S. leaders.
          </p>
          <p>
            “I actually give U.S. policymakers pretty good grades during the
            post-Cold War era,” Brands says. “If we had not done things like
            maintain extended alliances, we would have had much worse
            instability much sooner. The expansion of NATO, for instance, was
            the right thing to do.”
          </p>
          <p>
            What he does find dangerous is that today’s confusing international
            scene causes many to endorse retreating from involvement in the
            world. The election of President Donald Trump and his
            neoisolationist policies can be seen as a move in that direction,
            though the midterm results may well be a course correction.
          </p>

          <p>
            <Core.BoldText>Mary Sarotte</Core.BoldText>, who specializes in
            trans-Atlantic relations as the Marie-Josée and Henry R. Kravis
            Distinguished Professor of Historical Studies at SAIS, agrees: “I
            think the European leaders were looking for a total repudiation of
            Trump in the midterms and were disappointed not to see that. But
            they also saw some things they liked, such as the Democrats getting
            more votes nationwide.
          </p>
          <p>
            “So they are still holding their breath,” she says. “They can take
            four years of Trump, but not four years and a day. His re-election
            would be a clear sign that the trans-Atlantic relationship as we
            know it is breaking down—and the results of that could be terrible.
            It’s a very risky moment.”
          </p>
          <p>
            Brands emphasizes the dangers of a U.S. withdrawal from engagement
            with the rest of the world.
          </p>
          <p>
            “As messy as the world is, it’s going to be a heck of a lot messier
            if the U.S. is not involved,” he says. “A lot of what we do is not
            producing good outcomes but preventing bad outcomes. One of the
            reasons Europe has not relapsed into feverish instability and
            violent conflict is that the U.S. has been there providing security.
            The same is true in Asia.
          </p>
          <p>
            “It really is dangerous if we look at the world and say, ‘That looks
            really messy. Why bother?’ Because things would get a lot worse in a
            hurry if we are not there. The last time the international order
            collapsed was in 1939, and too many people have forgotten that.”
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    dragonGraph: file(
      relativePath: { regex: $directory }
      name: { eq: "dragon-graph" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default FeatureContent;
