import './Heading.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

const ChaoticHeading: React.FC = () => {
  const { chaoticWorld } = useStaticQuery<{
    chaoticWorld: Core.ImageAttributes;
  }>(
    graphql`
      {
        chaoticWorld: file(
          relativePath: {
            regex: "/issues/winter-2019/features/why-is-the-world-so-chaotic/"
          }
          name: { eq: "world-graphic" }
        ) {
          ...FullWidthImage
        }
      }
    `
  );

  return (
    <Core.Masthead color="light" className="ChaoticHeading">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        <div className="ChaoticHeading-content">
          <div className="ChaoticHeading-content-image">
            <Core.FullWidthImage image={chaoticWorld} alt="globe graphic" />
          </div>
          <div className="ChaoticHeading-content-title">
            <div className="ChaoticHeading-content-title-one">Why Is the</div>
            <div className="ChaoticHeading-content-title-two">World</div>
            <div className="ChaoticHeading-content-title-three">
              So Chaotic?
            </div>
            <div className="ChaoticHeading-content-title-byline">
              by Michael Hill
            </div>
          </div>
        </div>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default ChaoticHeading;
